import React, { useState, useEffect } from 'react';
import { addAndRemoveDisabledCls, isValidEmail, validateSubscriberData } from '../utils/utils';
import { submitInventivContactForm } from '../utils/campaign';
import Layout from '../components/layout';
import SEO from "../components/seo";

const Contact = () => {
    const seo = {
        metaDesc: 'Contact - Inventiv.org'
    }
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [subject, setSubject] = useState('Technical Support');
    const [emailSent, setEmailSent] = useState(false);
    //
    //
    const submit = async (e) => {
        e.preventDefault();

        if (name && email && phone && subject && message) {
            // call api
            const data = {
                email: email,
                name: name,
                phone: phone,
                subject: `[Inventiv] ${subject}`,
                message: message,
                site: 'inventiv.org'
            }

            // Validate data
            if (!validateSubscriberData(data)) {
                return;
            }

            // submit activeCampaign
            submitInventivContactForm(data);

            // Add to our database
            fetch("https://api.trustyip.com/subcribers/add", {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json"
                },
                "body": JSON.stringify(data)
            })
            .then(response => response.json())
            .then(response => {
                // alert('Thank you for contacting us. We will follow up with you soon');
                // reset
                setEmail('');
                setName('');
                setPhone('');
                setMessage('');
                // setSubject('');
                setEmailSent(true);
                //
                window.open("/thank-you");
            })
            .catch(err => {
                alert('There is something wrong. Please try again later!');
                console.log(err);
            });
            //
            addAndRemoveDisabledCls('contactBtn');

        } else {
            alert('Please fill your information');
        }

    }
    useEffect(() => {
        document?.body.classList.add('no-home');
    }, []);

    return (
        <Layout activeMenu={'contact'}>
            <SEO title="Contact - Inventiv.org" canonical='/contact' seo={seo} />

            <div class="sub-banner overview-bgi">
                <div class="container">
                    <div class="breadcrumb-area">
                        <h1>Contact Us</h1>
                    </div>
                </div>
            </div>

            {/* <!-- Rsvp start --> */}
            <div class="rsvp content-area-2" id="rsvp">
                <div class="container">

                    <div class="row">
                        <div class="col-12 col-md-6 wow fadeInUp delay-04s">
                            <h1 class="sec-clr bold">Get In Touch</h1>
                            <p>
                                <a href="mailto:info@inventiv.org">
                                    <i class="fa fa-envelope"></i>
                                    info@inventiv.org
                                </a>
                            </p>
                            <p>
                                <i class="fa fa-map-marker"></i>
                                Inventiv Foundation, Inc. PO Box 1065 Zephyr Cove, NV 89448
                            </p>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d49602.12194789618!2d-119.96757446575035!3d39.01228809804935!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1s%20Inventiv%20Foundation%2C%20Inc.%20PO%20Box%201065%20Zephyr%20Cove%2C%20NV%2089448!5e0!3m2!1sen!2s!4v1703077712228!5m2!1sen!2s" width="100%" height="450" style={{ border: 0 + 'px' }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                        <div class="col-12 col-md-6 wow fadeInUp delay-04s">
                            <form action="#" method="GET" enctype="multipart/form-data">
                                <div class="row">
                                    <h1 class="sec-clr bold">Fill out the Details</h1>
                                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div class="form-group name">
                                            <input type="text" name="name" class="form-control" placeholder="Name" value={name} onChange={e => setName(e.target.value)} />
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div class="form-group email">
                                            <input type="email" name="email" class="form-control" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)} />
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div class="form-group number">
                                            <input type="text" name="phone" class="form-control" placeholder="Phone" value={phone} onChange={e => setPhone(e.target.value)} />
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div class="form-group help">
                                            <select name="help" class="form-control forme-select" onChange={e => setSubject(e.target.value)}>
                                                <option value="" >How can we help?</option>
                                                <option value="Technical Support" selected>Technical Support</option>
                                                <option value="Product Demonstration">Product Demonstration</option>
                                                <option value="Pricing Information">Pricing Information</option>
                                                <option value="Purchase Quote">Purchase Quote</option>
                                                <option value="Others">Others</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div class="form-group message">
                                            <textarea class="form-control" name="message" placeholder="Write message" value={message} onChange={e => setMessage(e.target.value)}></textarea>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div class="send-btn text-center">
                                            <button type="button" id="contactBtn" class="btn btn-default btn-md btn-message" onClick={submit}>Send a message</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Rsvp end --> */}
        </Layout>
    )
};

export default Contact;